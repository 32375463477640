import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getClients,
  getCompanies,
  getWarehouses,
} from '../redux/reducers/accountSlice'
import Define from '../constants/define'
import { DebouncedFunc } from 'lodash'
import { FormInstance } from 'antd'

export const NOTI_BELL_CLICK_EVENT = 'notibell:click'

const useNotiBellClick = (
  form: FormInstance<any>,
  onFinish: DebouncedFunc<any>
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const onClickNotiBellItem = (e: any) => {
      const {
        client_id,
        company_id,
        warehouse_id,
        client_code_nom,
        company_code_nom,
        warehouse_code_nom,
      } = e.detail
      form.setFieldsValue({
        client: client_id,
        company: company_id,
        warehouse: warehouse_id,
      })
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }
      dispatch(
        getClients({
          ...filters,
          company_id,
          warehouse_id,
          company_code_nom,
          warehouse_code_nom,
        })
      )
      dispatch(
        getCompanies({
          ...filters,
          client_id,
          warehouse_id,
          client_code_nom,
          warehouse_code_nom,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          client_id,
          company_id,
          client_code_nom,
          company_code_nom,
        })
      )
      onFinish({
        client: {
          value: client_id,
          label: client_code_nom,
        },
        company: {
          value: company_id,
          label: company_code_nom,
        },
        warehouse: {
          value: warehouse_id,
          label: warehouse_code_nom,
        },
      })
    }
    window.addEventListener(NOTI_BELL_CLICK_EVENT, onClickNotiBellItem)
    return () => {
      window.removeEventListener(NOTI_BELL_CLICK_EVENT, onClickNotiBellItem)
    }
  }, [])
}

export default useNotiBellClick
